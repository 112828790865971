<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addRole' })"
    >
      {{ $t("roles.details") }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-file-alt"></i>
                {{ $t("roles.details") }}
              </h5>
            </template>
            <b-row>
              <b-col md="12" class="mb-3">
                <input-form
                  :placeholder="$t('main.enterName')"
                  :label="$t('main.ar.name')"
                  v-model="role.name_ar"
                  name="name"
                  validate="required"
                ></input-form>
              </b-col>
              <b-col md="12" class="mb-3">
                <input-form
                  :placeholder="$t('main.enterName')"
                  :label="$t('main.en.name')"
                  v-model="role.name_en"
                  name="name"
                  validate="required"
                ></input-form>
              </b-col>
            </b-row>
            <!-- <workingHours /> -->
          </b-card>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary">
                <i class="las la-file-alt"></i>
                {{ $t("roles.permissions") }}
              </h5>
            </template>
            <b-row>
              <div class="section_permission mr-3">
                <div class="panel-default w-100">
                  <div
                    class="panel-body col-md-6 col-lg-3 col-12"
                    v-for="permission in permissions"
                    :key="permission.id"
                  >
                    <b-form-checkbox
                      class="custom-checkbox-color-checked permission_box"
                      plain
                      v-model="role.permissions"
                      color="warning"
                      name="color"
                      :value="permission.id"
                    >
                      <span class="text-black font-size-16 mx-4">{{
                        permission.name
                      }}</span>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </b-row>
          </b-card>
        </div>
        <div class="form-actions">
          <b-button variant="outline-primary" @click="$router.back()">
            {{ $t("main.back") }}
          </b-button>
          <b-button
            v-if="$route.name !== 'showProduct'"
            variant="primary"
            type="submit"
            :disabled="loadingSubmit"
          >
            <span v-if="!loadingSubmit">
              {{ $t("main.save") }}
            </span>
            <spinner-loading v-else text="loading"></spinner-loading>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import roleServices from "@/modules/roles/services/roles";
export default {
  mounted() {
    core.index();
  },
  data() {
    return {
      role: {
        name_ar: "",
        name_en: "",
        permissions: [],
      },
      loadingSubmit: false,
      permissions: [],
    };
  },
  methods: {
    getRole() {
      roleServices.getRole(this.$route.params.id).then((res) => {
        this.role = {
          ...res.data.data,
          permissions: res.data.data.permission.map((item) => item.id),
        };
      });
    },
    getAllPermissions() {
      roleServices.getAllPermissions().then((res) => {
        this.permissions = res.data.data;
      });
    },
    onSubmit() {
      this.loadingButtonSubmit = true;
      if (this.$route.params.id) {
        roleServices
          .editRole(this.$route.params.id, this.role)
          .then((res) => {
            core.showSnackbar("success", res.data.message);
            this.$router.push({ name: "roles" });
          })
          .catch((err) => {
            core.showSnackbar("error", err.response.data.message);
          })
          .finally(() => {
            this.loadingButtonSubmit = false;
          });
      } else {
        roleServices
          .addRole(this.role)
          .then((res) => {
            core.showSnackbar("success", res.data.message);
            this.$router.push({ name: "roles" });
          })
          .catch((err) => {
            core.showSnackbar("error", err.response.data.message);
          })
          .finally(() => {
            this.loadingButtonSubmit = false;
          });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getRole();
    }
    this.getAllPermissions();
  },
};
</script>
<style lang="scss">
.input-with-icon-container {
  background-color: #fff;
  height: 45px;
  border: 1px solid #d7dbda;
  .input-with-icon {
    height: 100%;
    background: transparent !important;
    flex: 1;
    padding-inline-start: 20px !important;
    &.border-0 {
      border: none !important;
    }
  }
  .icon {
    min-width: 40px;
    height: 100%;
    font-size: 23px;
  }
}
.panel-default {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>
